@import url(https://fonts.googleapis.com/css2?family=Inter&family=Londrina+Solid&display=swap);
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d5d7e1;
}

h1 {
  font-family: "Londrina Solid";
  font-weight: 500;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

p {
  margin: 0;
}

button {
  padding: 10px 16px;
  color: #fff;
  border: transparent;
  background-color: black;
  font-weight: 700;
  font-size: 18px;
  border-radius: 12px !important;
  cursor: pointer;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: 0.1s;
}

button:disabled {
  background-color: rgb(175, 175, 175);
}

button:hover {
  background-color: rgb(80, 80, 80);
  scale: 1.03;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.MintPage_linksContainer__1dy9r {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.MintPage_linksContainer__1dy9r a {
  margin-right: 8px;
}

.MintPage_linksContainer__1dy9r a:hover {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  scale: 1.03;
}

.MintCard_downloadButton__EsURD {
  /* border: 2px solid black; */
  background-color: white;
}

.MintCard_downloadButton__EsURD:hover {
  background-color: white !important;
}

